<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Registration</h4>
                        <form @submit.prevent="createCertif()">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Registration code</label>
                                        <input v-model="form.registration_code"
                                        required
                                                type="text"
                                                placeholder="T/0001/EID POC "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Name and Surname</label>
                                        <multiselect :select="onselection(valueame)" :allow-empty="false" label="name_and_surname" track-by="name_and_surname" v-model="valueame" :options="nameCertification" :multiple="false" placeholder="Search trainee name">
                                            <template slot="singleLabel">{{ valueame ? valueame.name_and_surname : null }}</template>
                                        </multiselect>

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Professional registration number  </label>
                                        <input v-model="form.professional_registration_number"
                                        required
                                                type="text"
                                                placeholder="Professional registration number "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Date of last training </label>
                                        <!-- <date-picker v-model="valueame" placeholder="Date of last training " :first-day-of-week="1" lang="en"></date-picker> -->
                                        <input 
                                            v-model="form.date_of_last_training"
                                            required
                                            type="text"
                                            placeholder="Date of last training "
                                            class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Length of training </label>
                                        <input 
                                            v-model="form.length_of_training"
                                            required
                                            disabled
                                            type="text"
                                            placeholder="Length of training in days"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Time worked as EID tester </label>
                                        <input v-model="form.time_worked_as_eid_tester"
                                        required
                                                type="text"
                                                placeholder="Time worked as EID tester "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Phone </label>
                                        <input 
                                            v-model="form.phone"
                                            required
                                            name="phone"
                                            type="text"
                                            placeholder="Phone"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input v-model="form.email"
                                        required
                                                type="email"
                                                placeholder="Email"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Job Title</label>
                                        <input 
                                            v-model="form.job_title"
                                            required
                                            type="text"
                                            placeholder="Job title "
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Region </label>
                                        <multiselect required v-model="form.region" :options="options" label="name" :multiple="false" placeholder="Select Region"></multiselect>
                        
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >District</label>
                                        <input
                                        v-model="form.district"
                                        required
                                            type="text"
                                            placeholder="District"
                                            class="form-control"
                                        />

                                    </div>
                                </div>
                            </div>
                            <h4 class="card-title">Facility information </h4>
                            <div class="row mt-4">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Name of facility </label>
                                        <input 
                                            v-model="form.name_of_facility"
                                            required
                                            type="text"
                                            placeholder="Name of facility"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Facility address</label>
                                        <input
                                            v-model="form.facility_adress"
                                            required
                                            type="text"
                                            placeholder="Facility address"
                                            class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Type of testing site</label>
                                        <input
                                            v-model="form.type_of_testing_site"
                                            required
                                                type="text"
                                                placeholder="Type of testing site "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Head of testing site</label>
                                        <input v-model="form.head_of_testing_site"
                                        required
                                                type="text"
                                                placeholder="Head of testing site"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Phone</label>
                                        <input
                                            v-model="form.testing_phone"
                                            required
                                                type="text"
                                                placeholder="Phone"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input v-model="form.testing_email"
                                        required
                                            type="email"
                                            placeholder="Email "
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Head of facility</label>
                                        <input
                                            v-model="form.head_of_facility"
                                            required
                                                type="text"
                                                placeholder="Head of facility"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Facility Phone</label>
                                        <input
                                            v-model="form.facility_phone"
                                            required
                                                type="text"
                                                placeholder="Phone"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Facility Email</label>
                                        <input
                                            v-model="form.facility_email"
                                            required
                                                type="email"
                                                placeholder="Email "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import { axios_get, axios_post} from "../../../../helpers/helper";

    export default {
        page: {
            title: "Registration",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,Multiselect,PageHeader },
        data() {
            return {
                title: "Registration",
                valueame: null,
                user: null,
                district: null,
                loader:false,
                form: {
                    id: null,
                    registration_code: null,
                    name_and_surname: null,
                    professional_registration_number: null,
                    date_of_last_training: null,
                    length_of_training: null,
                    time_worked_as_eid_tester: null,
                    phone: null,
                    email: null,
                    job_title: null,
                    region: null,
                    district: null,

                    name_of_facility: null,
                    facility_adress: null,
                    type_of_testing_site: null,
                    head_of_testing_site: null,
                    testing_phone: null,
                    testing_email: null,
                    head_of_facility: null,
                    facility_phone: null,
                    facility_email: null,
                },
                length_date: [],
                nameCertification: [],
                date_of_last:'',
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "Registration",
                        active: true
                    }
                ],

                options: [],
                options1: [
                    "Assistant lab Tech ",
                    "QA lead",
                    "Focal Point",
                    "Other ",
                ],
                value:null,
                value1: null,
            };

        },

        mounted(){
            this.user=JSON.parse(localStorage.getItem('user'))
            axios_get("/certificate-refresher",{},(d)=>{
                    console.log(d)
                    this.nameCertification = d.filter(e=>{return e.region.country_id==this.user.country_id})
                    this.nameCertification = this.nameCertification.sort((a, b)=>{
                        if(a.name_and_surname < b.name_and_surname){
                            return -1;
                        }else{
                            return 1
                        }
                    });
                    console.log(this.nameCertification)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
            axios_get("/regions",{},(d)=>{
                console.log(d)
                this.options=d
                this.options=this.options.filter(e=>{return e.country_id==this.user.country_id})
                console.log(this.options)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
            console.log(this.valueame)
        },
        validations: {

        },
        methods: {
            onselection(name){
                
                console.log(this.valueame)
                console.log(name)
                if(name){
                    var dat = name.date_of_training.split('-')
                    var a = dat[0].split('/')
                    var b = dat[1].split('/')
                    var c = a[2].replaceAll(' ', '')+'-'+a[1].replaceAll(' ', '')+'-'+a[0].replaceAll(' ', '')
                    var d = b[2].replaceAll(' ', '')+'-'+b[1].replaceAll(' ', '')+'-'+b[0].replaceAll(' ', '')
                    var delta = Math.abs(new Date(d) - new Date(c)) / 1000;
                    this.length_date = Math.floor(delta / 86400);
                    this.form.id= name.id,
                    this.form.name_and_surname= name.name_and_surname,
                    this.form.date_of_last_training= name.date_of_training,
                    this.form.length_of_training= this.length_date,
                    this.form.phone= name.phone,
                    this.form.email= name.email,
                    this.form.job_title= name.responsibility,
                    this.form.region= name.region
                    this.form.name_of_facility= name.site.site_name

                    this.form.facility_adress= name.site.location
                    this.form.type_of_testing_site= name.site.type_facility
                    this.form.head_of_testing_site= name.site.head_of_testingsite
                    this.form.testing_phone= name.site.phone_of_head
                    this.form.testing_email= name.site.email_of_head
                    this.form.head_of_facility= name.site.head_of_facility
                    this.form.facility_phone= name.site.telephone
                    this.form.facility_email= name.site.facility_email
                }

                console.log(this.form)
            },

            
            change(){
                console.log(this.form.phone)
            },

            createCertif(){
                this.loader = true
                console.log(this.form.region.name)
                if(typeof this.form.region == 'object'){
                    console.log('this.form.region.name')
                    this.form.region = this.form.region.id
                }
                axios_post('/certif', this.form, (d)=>{
                    console.log('success', d)
                   // window.location.href = '/certification/add-certification-results'
                    this.valueame = null
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('fianle', f)
                    this.loader = false
                })
            }
            
        },

       /*  computed: {
            onselect: {
                console.log(name)
            }
        } */
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>